<template>
    <div>
		<b-modal ref="modalSendNotes" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group mt-4">
					<label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
					<e-select
						v-model="finalExpeditor"
						id="expeditor_id"
						:placeholder="labelTitleExpeditor"
						:options="finalExpeditors"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<label for="recipient_mail">{{ $t('notes.recipient_mail') }} *</label>
					<input name="recipient_mail" class="form-control" type="email" :class="{ 'is-invalid': error && error.indexOf('Erreur mail') > -1 }" v-model="recipient_mail">
				</div>
				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Model from '@/mixins/Model.js'
import Notes from '@/mixins/Notes.js'
import Tools from "@/mixins/Tools.js"

export default {
	name: "ModalSendNotes",
	mixins: [Model, Notes, Tools],
	data () {
		return {
			templates: [],
			template: null,
			processing: false,
			notes_ids: null,
            model_type: '',
            model_type_template: '',
			finalExpeditors: [],
            finalExpeditor: null,
            nomail: false,
			recipient_mail: '',
			error: [],

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleExpeditor: this.getTrad("model.search_expeditor")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = []

			this.templates = await this.loadAllModel(0, [this.model_type], this.modeltype_template)
			
			const fromAddress = await this.loadAllFromAddress()
			await this.FormatFromAddress(fromAddress)
		},
		openModal(notes_ids, model_type, modeltype_template) {
			this.notes_ids = notes_ids
            this.model_type = model_type
            this.modeltype_template = modeltype_template
			this.$refs.modalSendNotes.show()
		},
		closeModal() {
			this.$refs.modalSendNotes.hide()
		},
		async FormatFromAddress(fromAddress) {
			let array_mail = []
			let array_name = []
			this.finalExpeditors = []
			const selected = fromAddress["selected"]
			delete fromAddress["selected"]

			for (const [key, value] of Object.entries(fromAddress)) {				
				if (value.licenceconfig_key === "from_address") {
					if (value.licenceconfig_value != '' || value.licenceconfig_value != null) {
						const configValueAddress = value.licenceconfig_value.split(',')
						for (const address of configValueAddress) {
							array_mail.push(address.trim())
						}
					} 					
				}
				if (value.licenceconfig_key === "from_name" ) {
					if (value.licenceconfig_value != ''|| value.licenceconfig_value != null) {
						const configValueName = value.licenceconfig_value.split(',')
						for (const name of configValueName) {
							array_name.push(name.trim())
						}
					} 
				}
				if(array_mail.length > 0 && array_name.length > 0) {
					if(array_name[0] === 'Groomy') {
						this.nomail = true
					}
					break;
				}
			}

			for (const index in array_mail) {
				this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
			}

			this.finalExpeditor = this.finalExpeditors[0]
		},
		async checkForm() {
			this.error = []
			if(this.recipient_mail && !this.recipient_mail.match(this.regex_email)) {
				this.error.push('Erreur mail')
			}
			else {
				if(this.template) {
					this.processing = true
					await this.sendMailNotes(this.notes_ids, this.template.model_id, this.finalExpeditor, this.recipient_mail)
						.then((res) => {
							this.processing = false

							if(res != 'error') {
								this.successToast('gynecologie.send_dg_success')
								this.closeModal()
							}
						})
				}
				else {
					this.failureToast("formulaire.erreur_champs_non_remplis")
				}
			}
		}
	}
}
</script>